import { Box } from "@mui/system"
import React, { useRef, useState, useEffect, useContext } from "react"
import { MeetingContext } from "../../../context/MeetingContext"
import Video from "../../../_components/Video"
import muteIcon from '../../../asset/muteIcon.png'
import './meeting.css'

const StreamVideo =({meetingSession,isAudioVideo,handelScreen,sxProps})=>{
    const localVideoRef = useRef(null)
    const videoSlotRef = useRef(Array(2).fill().map((val,index)=>({ key:index, tileId:null, video:null, name:'', attendeeTitle:'',attendeeId:'',showVideoCard: false})))
    const [ roster, setRoster ] = useState(null)
    const rosterRef = useRef({})
    const [ allTiles, setTiles ] = useState([])
    const [presentAttendeeCount, setPresentAttendee] = useState(1)
    const [ remoteTileHeight, setRemoteTileHeight ] = useState(0)
    const context = useContext(MeetingContext)
    const { meetingAttendee, localUser, localVideo, attendeeId:localAttendeeId } = context

    useEffect(()=>{
        const observer = {
            videoDidStart:()=>{
                console.log('Video Start observer')
            }
        }
        meetingSession.audioVideo.addObserver(observer)
    },[meetingSession])
    useEffect(() =>{
        const marginBlock = sxProps.marginBlock
        let remoteVideoTileHeight = sxProps.height - ( 2*marginBlock )
    
        if(remoteVideoTileHeight<0 ){
            return
        }

        if(window.outerWidth <= 520 && presentAttendeeCount === 3){
            remoteVideoTileHeight = ( sxProps.height/2 )- ( 2*marginBlock )
        }
        
        setRemoteTileHeight(remoteVideoTileHeight)

    },[presentAttendeeCount])

    // Below useEffect for local video tile
    useEffect(()=>{
        if(!localVideoRef.current || !isAudioVideo){
            console.log("No local video element")
            return
        }

        const videoElement = localVideoRef.current
        const observer = {
            videoTileDidUpdate: tileState =>{
                if(!tileState.boundAttendeeId || !tileState.localTile || !tileState.boundExternalUserId){
                    return
                }
                
                meetingSession.audioVideo.bindVideoElement(
                    tileState.tileId,
                    videoElement
                );
                videoElement.id = `video-${tileState.boundAttendeeId}`;
                // attendeeJoined(tileState.boundExternalUserId)
            }
        }
        meetingSession.audioVideo.addObserver(observer);
        meetingSession.audioVideo.startLocalVideoTile();

    },[meetingSession,isAudioVideo])

    // Below useEffect for remote video tiles
    useEffect(()=>{
        const observer = {
            videoTileDidUpdate: tileState =>{
                if(!tileState.boundAttendeeId || tileState.localTile || tileState.isContent || !videoSlotRef.current ||!tileState.boundExternalUserId){
                    return
                }
                // const slot = findSlot(tileState.tileId)
                const slot = findSlot(tileState.boundExternalUserId)
                if(!slot){
                    throw new Error("Failed to find any remote peer")
                }

                const userDetails = meetingAttendee[tileState.boundExternalUserId]
                videoSlotRef.current = acquireVideoElement(tileState, slot, tileState.boundExternalUserId)
                if(tileState.active){
                    addTile(tileState.tileId)
                }

                meetingSession.audioVideo.bindVideoElement(tileState.tileId,slot.video)
                slot.video.id = `video-${tileState.boundAttendeeId}`;
                // attendeeJoined(tileState.boundExternalUserId)
            },
            audioVideoDidStop: (sessionStatus) => {
                const sessionStatusCode = sessionStatus.statusCode()
                if(localUser === 'bd'){
                    handelScreen("feedback");
                }
            },
            videoTileWasRemoved: tileId => {
                videoSlotRef.current = removeTileIdFromRef(tileId)
                removeTile(tileId)
            }
        }
        meetingSession.audioVideo.addObserver(observer)
    },[meetingSession])

    // Below useEffect used for current available members in the meeting
    useEffect(()=>{
        meetingSession.audioVideo.realtimeSubscribeToAttendeeIdPresence((presentAttendeeId, present, externalUserId)=>{
            if(!meetingAttendee[externalUserId]){
                return
            }
            if(present){
                attendeeJoined(externalUserId)
                const slot = findSlot(externalUserId)
                videoSlotRef.current = addMeetingAttendee(slot,externalUserId)
                if(localAttendeeId !== externalUserId || slot?.attendeeId.trim()){
                    setPresentAttendee(prevCount => prevCount + 1)
                }
            }
            else{
                attendeeExit(externalUserId)
                videoSlotRef.current = releaseMeetingAttendee(externalUserId)
                delete rosterRef[externalUserId]
                setPresentAttendee(prevCount => prevCount - 1)
            }
            
            

            meetingSession.audioVideo.realtimeSubscribeToVolumeIndicator(presentAttendeeId, (attendeeId, volume, muted, signalStrength) => {
                const attendeeRoster = {...rosterRef.current}
                if(attendeeRoster.hasOwnProperty(externalUserId)){
                    if(volume !== null) { attendeeRoster[externalUserId].volume = volume }
                    if(muted !== null) { attendeeRoster[externalUserId].muted = muted }
                    if(signalStrength !== null) { attendeeRoster[externalUserId].signalStrength = signalStrength }
                }     
                else{
                    attendeeRoster[externalUserId] ={
                        externalUserId,
                        attendeeId,
                        volume,
                        muted,
                        signalStrength
                    }
                }
                rosterRef.current = {...attendeeRoster}
                setRoster({...attendeeRoster})
            })
        })

    },[meetingSession])

    const addTile = (tileId) => {
        setTiles( prevTile => [...prevTile,tileId])
    }

    const removeTile = (tileId) => {
        setTiles( prevTile => prevTile.filter((prev)=> prev !== tileId))
    }

    const isTilePresent = (tileId) => allTiles.includes(tileId)

    // const findSlot = (tileId) =>{
    //     return ( videoSlotRef.current.find((slot) => slot.tileId === tileId) ||
    //     videoSlotRef.current.find((slot) => !slot.tileId))
    // }

    const findSlot = (externalId) => {  
        return ( videoSlotRef.current.find((slot) => slot.attendeeId === externalId) ||
        videoSlotRef.current.find((slot) => !slot.attendeeId))
    }

    const acquireVideoElement = (tileState, videoSlot, attendeeId) => (
        videoSlotRef.current.map((slot)=>(
            slot.key === videoSlot.key ? { ...slot, tileId: tileState.tileId, attendeeId:attendeeId, showVideoCard: true} : slot
        ))
    )

    const removeTileIdFromRef = (tileId) => (
        videoSlotRef.current.map((slot)=>(
            slot.tileId === tileId ? { ...slot, tileId:null } : slot
            ))
    )
     
    const releaseMeetingAttendee = (attendeeId) =>(
        videoSlotRef.current.map((slot) =>(
            slot.attendeeId == attendeeId ? { ...slot, tileId:null, video:null, name:'', attendeeTitle:'',attendeeId:'', showVideoCard:false } : slot
        ))
    )
    
    const addMeetingAttendee = (videoSlot, attendeeId) =>{
        const attendeeDetail = meetingAttendee[attendeeId]
        return videoSlotRef.current.map((slot) => (
            (localAttendeeId !== attendeeId && videoSlot.key === slot.key)?
            {...slot, name: attendeeDetail.name, attendeeTitle: attendeeDetail.title, attendeeId:attendeeId, showVideoCard: true} : slot
        ))
    }

    const attendeeJoined = (externalUserId) => {
        const title = meetingAttendee[externalUserId].title

        if(title ==='Doctor'){
            context.doctorJoin(externalUserId)
        }
        else if(title === 'Patient'){
            context.patientJoin(externalUserId)
        }
        else if(title === 'BD'){
            context.bdJoin(externalUserId)
        }
    }

    const attendeeExit = (externalUserId) => {
        const title = meetingAttendee[externalUserId].title

        if(title ==='Doctor'){
            context.doctorExit(externalUserId)
        }
        else if(title === 'Patient'){
            context.patientExit(externalUserId)
        }
        else if(title === 'BD'){
            context.bdExit(externalUserId)
        }
    }

    const getInitials = (name) => {
        let last = name.length > 1 ? name[1] : ''
        let shortName = name[0] + last
        return shortName.toUpperCase();
    }

    return(
        <Box className="meeting_container" sx={{height: sxProps?.height}}>
            <Box className="remote_user_container" display={`${presentAttendeeCount >1? "flex": "none" }`}>
            {videoSlotRef.current && videoSlotRef.current.map((slot) => (
                <Box
                display={`${slot.attendeeId ? "block": "none" }`}
                className={`remote_user
                ${!slot.attendeeId && "two_remote_user"}
                ${!slot.showVideoCard && "remove_remote_user" }
                ${!slot.tileId && slot.name && "remote_user_thumbnail"}
                `} 
                key={slot.key}
                >
                {/* {console.log('rendering roster',roster?.[slot.attendeeId]?.signalStrength)}
                { roster?.[slot.attendeeId]?.signalStrength === 0 ?  <p> Attendee Offline </p> :
                 roster?.[slot.attendeeId]?.signalStrength <= 0.5 && <p> Weak Internet </p> } */}
                { roster?.[slot.attendeeId]?.muted && <img className="attendee_mute_status" src={muteIcon} />}
                    <Video height={ remoteTileHeight } className={`video-screen`} ref={video=>slot.video=video} /> 
                {!slot.tileId && slot.name && 
                    <p className='user_initial'> {getInitials(slot.name)} </p>
                }
                </Box>
            ))}
            </Box>
            <Box className={`local_user 
                ${presentAttendeeCount>1 && "resize_local_user"}
                ${!localVideo && "local_user_thumbnail"}
                `}>
                { roster?.[localAttendeeId]?.muted && <img className="attendee_mute_status" src={muteIcon} />}
                <Video className='local-video-screen' ref={localVideoRef} />
                {!localVideo && 
                    <p className='user_initial'> {getInitials(meetingAttendee[localAttendeeId]?.name)} </p>
                }
            </Box>
        </Box>
    )
}

export default StreamVideo