import React, { useContext, useEffect, useState } from "react"
import { MeetingContext } from "../../../context/MeetingContext"

export default function Message({}){
    const [infoText,setInfo] = useState('')
    const context = useContext(MeetingContext)
    const {
        meetingAttendee,
        attendeeId,
        doctor,
        patient,
        bd
    } = context 
    useEffect(()=>{
        if( !meetingAttendee || !doctor.joined && !patient.joined && !bd.joined){
            return
        }

        if(doctor.joined && patient.joined && !bd.exit){
            setInfo('')
        }
        else if(!patient.joined && !doctor.joined && bd.joined){
            setInfo('No One has joined the call.')
        }
        else if(doctor.exit && doctor.id !== attendeeId ){
            setInfo('Doctor left the call.')
        }
        else if(bd.exit && bd.id !== attendeeId && (!doctor.exit || !patient.exit)){
            setInfo('BD left the call.')
            const id = setInterval(()=>{
                setInfo('')
            },3000)

            return(()=>{
                clearInterval(id)
            })
        }
        else if(patient.exit && patient.id !== attendeeId){
            setInfo('Patient left the call.')
        }
        else if(!doctor.joined && doctor.id !== attendeeId){
            setInfo('Thank you for your patience. Waiting for the Doctor to join.')
        }
        else if(!patient.joined && patient.id !== attendeeId){
            setInfo('Thank you for your patience. Waiting for the Patient to join.')
        }

    },[ doctor, patient, bd ])

    return(
        <>
        { infoText.trim() &&
        <div className="info-text">
            {infoText}
         </div>   
        }
        </>
    )
}