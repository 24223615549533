import React, { useEffect, useState, useContext, useRef } from "react";
import {
    ConsoleLogger,
    DefaultDeviceController,
    DefaultMeetingSession,
    LogLevel,
    DefaultBrowserBehavior,
    MeetingSessionConfiguration
  } from 'amazon-chime-sdk-js';
import { joinMeeting } from "../../../services";
import './meeting.css'
import StreamAudio from "./StreamAudio";
import StreamVideo from "./StreamVideo";
import MeetingController from "./MeetingController";
import Header from "../../../common/Header";
import { Box } from "@mui/material";
import { MeetingContext } from "../../../context/MeetingContext";
import Message from "./Message";
import Loader from "../../../common/Loader";
import useBrowserCompatible from "../../../customHooks/browserCompatible";
import AppNotCompatible from "../../../_components/AppNotCompatible";
import MeetingStatusNotifier from "../../../_components/MeetingStatusNotifier";

const logger = new ConsoleLogger('MyLogger', LogLevel.INFO);
const deviceController = new DefaultDeviceController(logger);
const sectionHeight ={
    header: 60,
    footer: 75,
    blockMargin: 5
}
export default function Meeting_Check({ appId, handelScreen, setMeetingId }){
    const [loading, setLoading] = useState(false);
    const [meetingSession,setMeetingSession]=useState(null)
    const [isAudioVideo,setAudioVideo] = useState(false)
    const [canFlipCamera,setCanFlipCamera] = useState(false)
    const [videoQuality,setVideoQuality] = useState(null)
    const [notifyMessage,setNotifyMessage] = useState(null)
    const { compatibilityInfo, isCompatible, setAttributes } = useBrowserCompatible(appId)
    // const [isLowBattery,setLowBattery] = useState(false)
    const [ meetingHeight, setMeetingHeight] = useState(0)
    const meetingRoomRef = useRef(null)
    const oldSendBandwidthRef = useRef(0)
    const oldRecvBandwidthRef = useRef(0)
    const context = useContext(MeetingContext)
    const {attendeeId} = context
    
    useEffect(()=>{
        setLoading(true)
        joinMeeting(appId,attendeeId).then(function(res){
            const {meeting_response,attendee_response} = res.data
            const meetingConfiguration = new MeetingSessionConfiguration(meeting_response,attendee_response)
            const meetingSession = new DefaultMeetingSession(meetingConfiguration, logger, deviceController)
            context.setMeetingId(meeting_response.Meeting.MeetingId)
            setMeetingId(meeting_response.Meeting.MeetingId)
            setMeetingSession(meetingSession)
            setLoading(false)
        })
        .catch(err =>{ alert("Try again !!") })

    },[])

    useEffect(()=>{
        const subtractHeight = sectionHeight.header + sectionHeight.footer + (sectionHeight.blockMargin*2)
        const meetingRoomHeight = meetingRoomRef.current?.offsetHeight
        if( loading && meetingRoomRef.current && meetingRoomHeight>0){
            setMeetingHeight(meetingRoomHeight - subtractHeight)
        }
  
    },[loading])

    useEffect(()=>{
        if(!meetingSession){
            return
        }

        setupAudioVideoInput().then(()=>{
            const muteStateObserver = {
                audioInputMuteStateChanged: (device, muted) => {
                    console.warn( "Device", device, muted ? "is muted in hardware" : "is not muted" );
                },
            }

            const observer = {
                eventDidReceive:(name,attributes)=>{
                    switch (name){
                        case 'meetingStartRequested':
                            setAttributes(attributes)
                    }
                }
            }

            const connectionObserver = {
                audioVideoDidStartConnecting: reconnecting => {
                    if(reconnecting){
                        console.log('audioVideoDidStartConnecting')
                        setNotifyMessage('Slow Internet! Reconnecting...')
                    }
                }
            }

            meetingSession.eventController.addObserver(observer);
            meetingSession.audioVideo.addDeviceChangeObserver(muteStateObserver);
            meetingSession.audioVideo.start();
            meetingSession.audioVideo.addObserver(connectionObserver)
        })

    },[meetingSession])

    useEffect(()=>{
        if(!meetingSession){
            return
        }

        const observer ={
            oldSendBandwidthkbs: oldSendBandwidthRef.current,
            oldRecvBandwidthKbs: oldRecvBandwidthRef.current,
            metricsDidReceive: (clientMetricReport) =>{
                const metricReport = clientMetricReport.getObservableMetrics()

                const availableSendBandwidthkbs = metricReport.availableOutgoingBitrate / 1000
                const availableRecvBandwidthKbs = metricReport.avaialableIncomingBitrate / 1000

                if( availableSendBandwidthkbs && oldSendBandwidthRef.current !== availableSendBandwidthkbs){
                    oldSendBandwidthRef.current = availableSendBandwidthkbs
                    console.log('availableSendBandwidthkbs',availableSendBandwidthkbs)
                    if(availableSendBandwidthkbs>1001){
                        setVideoQuality('360p')
                    }
                    else if(availableSendBandwidthkbs>351 && availableSendBandwidthkbs<1000){
                        setVideoQuality('180p')
                    }
                    else if(availableSendBandwidthkbs>50 && availableSendBandwidthkbs<350){
                        setVideoQuality('100p')
                    }
                }

                // if(this.oldRecvBandwidthKbs !== availableRecvBandwidthKbs){
                //     console.log(`Receiving bandwidth is -- ${availableRecvBandwidthKbs}, and old bandwidth is -- ${this.oldSendBandwidthkbs}`)
                //     this.oldRecvBandwidthKbs = availableRecvBandwidthKbs
                // }
            }    
        }
        meetingSession.audioVideo.addObserver(observer)
    },[meetingSession])

    useEffect(() =>{
        if(!videoQuality){
            return
        }
        setNotifyMessage(null)
        switch(videoQuality){
            case '360p':
                meetingSession.audioVideo.chooseVideoInputQuality(640,360,15)
                meetingSession.audioVideo.setVideoMaxBandwidthKbps(800);
                break
            case '180p':
                meetingSession.audioVideo.chooseVideoInputQuality(320,180,15)
                meetingSession.audioVideo.setVideoMaxBandwidthKbps(600);
                break
            case '100p':
                meetingSession.audioVideo.chooseVideoInputQuality(320,180,15)
                meetingSession.audioVideo.setVideoMaxBandwidthKbps(600);
                setNotifyMessage('Slow Internet! Please check your connection.')
                break
            // case '0p':
            //     meetingSession.audioVideo.chooseVideoInputQuality(320,180,15)
            //     meetingSession.audioVideo.setVideoMaxBandwidthKbps(300);
            //     setNotifyMessage('No Internet!')
            //     break
        }
    },[videoQuality])

    const setupAudioVideoInput =async()=>{
        const audioInputDevices = await meetingSession.audioVideo.listAudioInputDevices();  
        await meetingSession.audioVideo.startAudioInput(audioInputDevices[0].deviceId)
        
        const defaultBrowserBehaviour = new DefaultBrowserBehavior();
        const audioOutputDevices = await meetingSession.audioVideo.listAudioOutputDevices();
        if(defaultBrowserBehaviour.supportsSetSinkId() && audioInputDevices[0].deviceId){
            await meetingSession.audioVideo.chooseAudioOutput(audioOutputDevices[0].deviceId)
        }

        const videoInputDevices = await meetingSession.audioVideo.listVideoInputDevices();
        await meetingSession.audioVideo.startVideoInput(videoInputDevices[0].deviceId)

        if( videoInputDevices && videoInputDevices.length > 1 ){
            setCanFlipCamera(true)
        }
        setAudioVideo(true)
    }

    return(
        <Box className="meeting-room" ref={meetingRoomRef}>
            <Header sxProps={{height:sectionHeight.header + 'px'}} meetingSession={meetingSession} handelScreen={handelScreen} />
        {loading && !compatibilityInfo ? <Loader/>
        :
        <>
            {!isCompatible && <AppNotCompatible compatibilityInfo={compatibilityInfo} />}
            
            {meetingSession && isCompatible &&
            <>
            { notifyMessage && <MeetingStatusNotifier message={notifyMessage} />}
            <StreamAudio isAudioVideo={isAudioVideo} meetingSession={meetingSession} />
            <StreamVideo sxProps={{height:meetingHeight, marginBlock: sectionHeight.blockMargin}} isAudioVideo={isAudioVideo} meetingSession={meetingSession} handelScreen={handelScreen} />
            <Message/>
            <MeetingController sxProps={{height:sectionHeight.footer + 'px'}} meetingSession={meetingSession} handelScreen={handelScreen} canFlipCamera={canFlipCamera} />
        </>}
        </>
    }        
    </Box>


    )
}