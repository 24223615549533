import React, { useState, useEffect, useContext } from "react";
import { Grid } from "@mui/material";
import { Box } from "@mui/system";
import ControllerIcon from "../../../_components/ControllerIcon";
import videoOnImg from  "../../../asset/Video on.png";
import videoOffImg from "../../../asset/Video off.png";
import unMuteImg from "../../../asset/Unmute.png";
import muteImg from "../../../asset/Mute.png";
import endCallImg from "../../../asset/End Call.png";
import flipCameraImg from '../../../asset/Flip Camera.png';
import flipCameraBlockImg from '../../../asset/Flip Camera Blocked.png';
import removePerson from "../../../asset/RemovePerson.svg"
// import removePersonBlocked from "../../../asset/RemovePersonBlocked.svg";
import { MeetingContext } from "../../../context/MeetingContext";
import { removeAttendee } from "../../../services";

export default function MeetingController({ meetingSession,handelScreen,sxProps }){
    const [endCallPopup,setEndCallPop] = useState(false)
    const [videoBlock,setVideoBlock] = useState(true)
    const [cameraFlip,setCameraFlip] = useState(false)
    const [muted,setMuteAudio] = useState(false)
    const context = useContext(MeetingContext)
    const {
        localUser,
        bd,
        meetingId
    } = context

    const videoOn = async () => {
        const videoInputDevices = await meetingSession.audioVideo.listVideoInputDevices();
        await meetingSession.audioVideo.startVideoInput(videoInputDevices[0].deviceId);
        meetingSession.audioVideo.startLocalVideoTile();
        context.setLocalVideoTile(true)
        setVideoBlock(true);
    }
    
    const videoOff = async () => {
        meetingSession.audioVideo.stopLocalVideoTile();
        await meetingSession.audioVideo.stopVideoInput();
        context.setLocalVideoTile(false)
        setVideoBlock(false);
    }

    // const muted = meetingSession.audioVideo.realtimeIsLocalAudioMuted();
    const muteCall = (mute) =>{
        if(mute){
            meetingSession.audioVideo.realtimeMuteLocalAudio();
        }
        else{
            meetingSession.audioVideo.realtimeUnmuteLocalAudio();
        }
        setMuteAudio(mute)            
    }

    const flipCamera = async() => {
        let videoDeviceId = 0
        const videoInputDevices = await meetingSession.audioVideo.listVideoInputDevices();
        if(!cameraFlip && videoInputDevices && videoInputDevices.length > 1){
            videoDeviceId = 1
        }

        await meetingSession.audioVideo.startVideoInput(videoInputDevices[videoDeviceId].deviceId)
        setCameraFlip(!cameraFlip)
    }

    const endCall = async () => {
        await meetingSession.audioVideo.stopAudioInput();
        await meetingSession.audioVideo.stop()
        handelScreen('feedback')
    }

    const removeBD = () =>{
        if (bd.joined) {
            removeAttendee(meetingId)
        }
    }
 
    return(
        <>
        <Grid container spacing={2} className="controller-footer" sx={{...sxProps}}>
            {!videoBlock && <Grid xs={2} item className="icon-box" onClick={()=> videoOn()}>
                <ControllerIcon imageUrl={videoOffImg} text='Video On' type='default'/>
            </Grid>}
            {videoBlock && <Grid xs={2} item className="icon-box" onClick={()=> videoOff()}>
                <ControllerIcon imageUrl={videoOnImg} text='Video Off' type='default'/>
            </Grid>}
            
            {!muted && <Grid xs={2} item className="icon-box" onClick={()=> muteCall(true)}>
                <ControllerIcon imageUrl={unMuteImg} text='Mute' type='default'/>
            </Grid>}

            {muted && <Grid xs={2} item className="icon-box" onClick={()=> muteCall(false)}>
                <ControllerIcon imageUrl={muteImg} text='UnMute' type='default'/>
            </Grid>}

            {/* <Grid xs={2} className="icon-box" onClick={()=> flipCamera()}>
                <ControllerIcon imageUrl={canFlipCamera? videoBlock?flipCameraImg:flipCameraBlockImg:flipCameraBlockImg} text='Flip Camera' type='default'/>    
            </Grid> */}
            <Grid xs={2} item className="icon-box" onClick={()=> setEndCallPop(true)}>
                <ControllerIcon imageUrl={endCallImg} text='End Call' type='default'/>
            </Grid>
            {localUser === 'doctor' && 
            <Grid xs={2} item className="icon-box" onClick={() => removeBD()}>
                <ControllerIcon imageUrl={ removePerson } text='Remove BD' type='default' />
            </Grid>
            }
        </Grid>

        {endCallPopup &&
            <Box className='exitCall'>
                <Grid container spacing={2}>
                    <Grid className='exitCallHead' item xs={12}> Exit Call ? </Grid>
                    <Grid className='exitCallBody' item xs={12}> Are you sure you want to end this call? </Grid>
                    <Grid item xs={6}>
                        <button
                         onClick={() => setEndCallPop(false)} 
                         className="exitCallButt">Not yet</button>
                    </Grid>
                    <Grid item xs={6}>
                        <button 
                        onClick={() => endCall()} 
                        className="exitCallButt">Yes</button>
                    </Grid>
                </Grid>
            </Box>
        }
        </>
    )
}