export const InitialState ={
    loading: false,
    localVideo: true,
    screen: 'welcome',
    localUser: '',
    attendeeId: '',
    appId:'',
    meetingAttendee: {},
    meetingId:null,
    doctor:{
        joined: false,
        exit: false,
        id: null
    },
    patient:{
        joined: false,
        exit: false,
        id: null
    },
    bd:{
        joined: false,
        exit: false,
        id: null
    },
}