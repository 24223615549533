import React, {useEffect, useRef, useState,} from 'react';

const useTimer = () =>{
    const [timer,setTimer] = useState("02:30")
    const [startTime,setStartTimer] = useState(false)
    const timerRef = useRef(null)

    useEffect(()=>{
        if(startTime)  clearTimer(getDeadTime())
    },[startTime])

    const getDeadTime = ()=>{
        let deadline = new Date()
        deadline.setSeconds(deadline.getSeconds() + 30)
        deadline.setMinutes(deadline.getMinutes() + 2)
        return deadline
    }

    const clearTimer = (e) =>{
        setTimer("02:30")
        // startTimer(e)
        if(timerRef.current) clearInterval(timerRef.current)
        const id = setInterval(()=>{
            startTimer(e)
        },1000);
        
        timerRef.current = id
        // return ()=>{
        //     clearInterval(id)
        // }
    }

    const startTimer = (e) => {
        let {total, minutes, seconds} = getRemainingTime(e)
        if(total>=0){
            setTimer(
                (minutes > 9 ? minutes : "0" + minutes) +
                ":" +
                (seconds > 9 ? seconds : "0" + seconds)
            )
        }
    }

    const getRemainingTime = (e) =>{
        const total = Date.parse(e) - Date.parse(new Date());
        const seconds = Math.floor((total / 1000) % 60);
        const minutes = Math.floor((total / 1000 / 60) % 60);

        return { total, minutes, seconds };
    }

    const resetTimer = () =>{
        clearTimer(getDeadTime())
    }
    return { timer, startTime, resetTimer, setStartTimer }
}

export default useTimer