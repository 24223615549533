import React, { useEffect, useState } from 'react';
import { Dialog, DialogContent, Typography } from '@mui/material';
import lockIcon from '../asset/lockIcon.png'
import websiteURL from '../asset/websiteURL.png'
import './style.css'
import { Box } from '@mui/system';
import AppNotCompatible from '../_components/AppNotCompatible';

const reloadPage=()=>{
    window.location.reload()
}

const PermissoionSettings = ({permission, open, onClose}) =>{
    // const { errType } = permissionError

    const systemDeniedDialogContent = () =>{
        return(
            <Box>
                <Typography variant="h4" component='h4' sx={{fontSize: ['18px', '18.5px', '19px', '19.5px', '20px']}}>
                    Can't use your camera or microphone
                </Typography>
                <Typography>
                    Your Browser might not have access to your camera or microphone.
                    To fix this problem, kindly allow camera and microphone permission
                    from device setting 
                </Typography>
            </Box>
        )
    }

    const userDeniedDialogContent = () =>{
        return(
            <Box>
            <Typography variant='h4' component='h4' sx={{fontSize: ['18px', '18.5px', '19px', '19.5px', '20px']}}>
                How do I enable camera and microphone services?
            </Typography>
            <section>
                <p className='permission-steps'> 1. Click on the 
                    <span> <img className='lock-icon' src={lockIcon} alt='Lock Icon' /> </span> 
                    icon located next to the website URL on the address bar. </p>
                <div>
                    <img src={websiteURL} alt='Website URL' />
                    <p className='permission-sub-steps'> a. Click on 
                    <strong> Site settings </strong> if using an Android device or 
                    <strong> Website settings </strong> for Safari in iOS </p>
                </div>
                
                <p className='permission-steps'> 2. For each of the fields <strong> Camera, </strong> and 
                <strong> Microphone </strong> open the dropdown and select Allow. </p>
                <p> 3. Allow permissions through device setting as well, for each of the fields 
                <strong> Camera, </strong> and <strong> Microphone </strong> </p>
                <p className='permission-steps'> 4. The browser will ask you to reload the page for the changes to reflect </p>
                <p className='reloadPermissionDiv'>
                    <button className="reloadForPermission" onClick={()=>reloadPage()} >Reload</button>
                </p>
            </section>
            </Box>
        )
    }

    const notStartVideoDialogContent = () =>{
        return(
            <Box>
                <Typography variant='h4' component='h4' sx={{fontSize: ['18px', '18.5px', '19px', '19.5px', '20px']}}>
                    Can't Start your camera or microphone 
                </Typography>
                <Typography> Another application (Zoom, Webex) or browser tab (Google Meet, Messager Video)
                    might already be using your webcam, Please turn off other camera before proceeding.
                </Typography>
            </Box>
        )
    }

    const renderDialogContent = () =>{
        // switch (errType) {
        //     case 'SystemPermissionDenied':
        //         return systemDeniedDialogContent()
        //     case 'UserPermissionDenied':
        //         return userDeniedDialogContent()
        //     case 'CouldNotStartVideoSource':
        //         return notStartVideoDialogContent()
        // }
        return userDeniedDialogContent()
    }

    return(
        <>
        {/* { !errType ?
            <AppNotCompatible  isCompatible={true} compatibilityInfo={null} />: */}

            <Dialog open={open} onClose={onClose} 
        sx={{minWidth: ['300px', '300px', '300px', '300px', '370px']}}
        >
            <DialogContent>
                {open && renderDialogContent()}
            </DialogContent>
        </Dialog>
        {/* } */}
        </>
    )
}

export default PermissoionSettings