import React, { useState, useEffect, useContext } from 'react';
import Grid from '@mui/material/Grid';
import { UAParser } from "ua-parser-js";
import '../../../style.css';
import moment from 'moment'
import PermissoionSettings from '../../../common/PermissionSettings';
import { MeetingContext } from '../../../context/MeetingContext';
import { detectBrowser } from '../../../utility/detectBrowser';
import AppNotCompatible from '../../../_components/AppNotCompatible';
import { deviceInfo } from '../../../services';

export default function Welcome({ appDetails, handelScreen, user, apiStatus }) {

    const [iAgree, setIAgree] = useState(false);
    const [iAccept, setIAccept] = useState(false);
    const [mobileOS, setMobileOS] = useState("")
    const [permission, setPermission] = useState(true)
    const [openDialog, setDialog] = useState(false)
    const [browserCheck, setBrowserName] = useState(true)
    const context = useContext(MeetingContext)
    const {appId, attendeeId,meetingId, meetingAttendee} = context

    // const clickIAgree = async () => {
    //     let stream = null;
    //     const constraints = {
    //         video: true,
    //         audio: true
    //     };
    //     if (!iAgree) {
    //         stream = await navigator.mediaDevices.getUserMedia(constraints);
    //     }
    //     setIAgree(!iAgree)
    // }

    useEffect(() => {
        const browserName = detectBrowser()
        if(browserName === 'Not Supported'){
            setBrowserName(false)
        }

        var userAgent = navigator.userAgent || navigator.vendor || window.opera;
        if (/Android/i.test(userAgent)) {
            setMobileOS("Android")
        }
        else if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
            setMobileOS("IOS")
        } else {
            setMobileOS("Others")
        }
        // clickIAgree();
        // navigator.permissions.query({ name: "camera", name: 'microphone' }).then((permissionStatus) => {
        //     permissionStatus.onchange = () => {
        //         window.location.reload();
        //     }
        // })
    }, [])

    useEffect(()=>{

        if(Object.keys(meetingAttendee).length<=0){
            return
        }
        let userAgent = navigator.userAgent
        const parserResult = getDeviceDetails()
        let device = null
        if(parserResult){
            device = {
                browser: parserResult?.browser,
                os: parserResult?.os
            }
        }

        const data = {
            meetingId: meetingId,
            appointmentId: appId,
            reason: browserCheck? 'No reason': "Browser Not Compatible",
            userId: attendeeId,
            userTitle: meetingAttendee[attendeeId]?.title,
            userName: meetingAttendee[attendeeId]?.name,
            deviceDetails: {raw:userAgent,currentDevice: device}
        }

        deviceInfo(data)
    },[meetingAttendee])

    const clickIAgree = async () => {
        let stream = null;
        let constraints
        if (user == 'bd') {
            constraints = {
                audio: true,
                video: true,
            };
        } else {
            constraints = {
                video: true,
                audio: true
            };
        }
        if (!iAgree) {
            navigator.mediaDevices.getUserMedia({audio:true, video: true})
                .then(res => {
                    console.log(res, "success allow");
                    setIAgree(!iAgree);
                    // handelScreen("meeting");
                    // localStorage.setItem("joinTime", new Date());
                    setPermission(true)
                })
                .catch(err => {
                    console.error(err)
                    setDialog(true)
                    setPermission(false)
                })
        }
    }

    const dateTimeFormat = (timestamp) => {
        timestamp = new Date(timestamp)
        let final = ""
        final = final + timestamp.toLocaleString('en-US', { hour: 'numeric', minute: 'numeric', hour12: true })
        final = final + " | "
        final = final + moment(timestamp).format('DD MMM YYYY');
        // timestamp.toLocaleDateString('it-IT', { day: 'numeric', month: 'short', year: 'numeric' }).toLocaleUpperCase();
        return final
    }

    const joinCall =()=>{
        context.setLocalVideoTile(true)
        handelScreen("meeting")
        localStorage.setItem("joinTime", new Date())
    }

    const getDeviceDetails = () =>(
        navigator && navigator.userAgent ? new UAParser(navigator.userAgent).getResult() : null
    )
    return (
        <>
            <span id="screen1" style={{ "overflow": "hidden" }}>
                {!permission &&
                    <span>
                        <PermissoionSettings permission={permission} open={openDialog} onClose={()=>setDialog(false)}/>
                    </span>
                }
               
                <div className="row">
                    <div className="col headder">
                        <center>
                            <img alt="img" className="logo" src="/asset/Logo.png" />
                        </center>
                    </div>
                </div>
                { !browserCheck ?
                    <AppNotCompatible isCompatible={false} compatibilityInfo={null} />:
                <>
                <div style={{ "position": "absolute", "right": "0" }} className="row">
                    <div className="col content1">
                        <img alt="img" className="rightCircle" src="/asset/right.png" />
                    </div>
                </div>
                {apiStatus === 'pending' && 
                    <div className='row' style={{marginTop:'24%'}}>
                    <div className='col content forPatient'>
                    <div className='helloTxt'>
                        Please wait setting up meeting session for you
                    </div> </div> </div>
                }
                {apiStatus === 'failed' &&
                    <div className='row' style={{marginTop:'24%'}}>
                        <div className='col content forPatient'>
                            <div className='helloTxt'> Something went wrong... Please try again  <button className='reload-page' onClick={() => { window.location.reload(); }}> reload </button> </div>
                        </div>
                    </div>

                }
                { apiStatus === 'success' &&
                <div className="row" style={{ "marginTop": "22%" }}>
                    {user === 'doctor' &&
                        <div className="col content forPatient" id="forPatient">
                            <div className='helloTxt'>
                                Hello {appDetails?.doctorDetails?.doctorName},
                            </div>
                            Your video consultation appointment with<br />
                            <span style={{ "color": "#3D7CC9", "fontFamily": "proxima_nova_bold", "marginTop": "10px", "marginBottom": "10px" }}>
                                {appDetails?.patientDetails?.patientName}
                            </span>&nbsp;
                            is scheduled at
                            <br />{dateTimeFormat(appDetails?.patientDetails?.appStartTime)}
                        </div>
                    }
                    {user === 'patient' &&
                        <div className="col content forPatient" id="forPatient">
                            <div className='helloTxt'>
                                Hello {appDetails?.patientDetails?.patientName},
                            </div>
                            Your Video Consultation<br /> Appointment with &nbsp;
                            <span style={{ "color": "#3D7CC9", "fontFamily": "proxima_nova_bold", "marginTop": "10px", "marginBottom": "10px" }}>
                                {appDetails?.doctorDetails?.doctorName}
                            </span> is<br />
                            scheduled at {dateTimeFormat(appDetails?.patientDetails?.appStartTime)}
                        </div>
                    }
                    {user === 'bd' &&
                        <div className="col content forPatient" id="forPatient">
                            <div className='helloTxt'>
                                Hello, {appDetails?.BDName},
                            </div>
                            Your Patient &nbsp;
                            <span style={{ "color": "#3D7CC9", "fontFamily": "proxima_nova_bold", "marginTop": "10px", "marginBottom": "10px" }}>
                                {appDetails?.patientDetails?.patientName}
                            </span>&nbsp;
                            consultation appointment with &nbsp;
                            <span style={{ "color": "#3D7CC9", "fontFamily": "proxima_nova_bold", "marginTop": "10px", "marginBottom": "10px" }}>
                                {appDetails?.doctorDetails?.doctorName}
                            </span><br/>
                            is scheduled at {dateTimeFormat(appDetails?.patientDetails?.appStartTime)}
                            {/* <div style={{ "paddingBottom": "10px" }}>Your organised consultation is as below:</div>
                            <div style={{ "paddingBottom": "10px" }}>
                                Doctor: &nbsp;
                                <span style={{ "color": "#3D7CC9", "fontFamily": "proxima_nova_bold" }}>
                                    {appDetails?.doctorDetails?.doctorName}
                                </span><br />
                            </div>
                            <div style={{ "paddingBottom": "10px" }}>
                                Patient: &nbsp;
                                <span style={{ "color": "#3D7CC9", "fontFamily": "proxima_nova_bold" }}>
                                    {appDetails?.patientDetails?.patientName}
                                </span><br />
                            </div>
                            Video Starts at {dateTimeFormat(appDetails?.patientDetails?.appStartTime)} */}
                        </div>
                    }
                </div>
                }
            
                <div className="row joinVideoDivDoc">
                    <div className="col-12">
                        <button
                            disabled={!iAgree || apiStatus=== 'pending' || apiStatus === 'failed'} 
                            onClick={() => { joinCall() }}
                            className="joinVideo" 
                            id="joinVideo" style={{ "width": "280px" }}>
                            Join Video Call
                        </button>
                    </div>
                </div>
                { apiStatus === 'success' && <div className={user === 'doctor' ? "row permissionTxt" : "row permissionTxt1"}>
                    <div className="col content">
                        <Grid onClick={() => clickIAgree()} container spacing={2}>
                            <Grid item lg={5} xs={1} style={{"textAlign":"right"}}>
                                {!iAgree && <img style={{ "paddingTop": "-1px" }} alt="img" src="/asset/unSelected.svg" />}
                                {iAgree && <img style={{ "paddingTop": "-1px" }} alt="img" src="/asset/Selected.svg" />}
                            </Grid>
                            <Grid item lg={7} xs={11} style={{ "fontSize": "12px","textAlign":"left" }}>
                                {/* Allow permissions to access {user === 'bd' ? '' : 'Camera &'} Microphone */}
                                Allow permissions to access Camera & Microphone
                            </Grid>
                        </Grid>
                        {user === 'doctor' &&
                            <Grid style={{ "marginTop": "20px" }} container spacing={2}>
                                <Grid item xs={1} style={{ "marginTop": "-2px", "color": "grey", "fontSize": "15px", "fontWeight": "bold" }}>
                                    ⓘ
                                </Grid>
                                <Grid item xs={11} style={{ "fontSize": "14px" }}>
                                    : This link will be active until {moment(appDetails?.patientDetails?.appStartTime).format('MMMM Do')}
                                </Grid>
                            </Grid>
                        }
                        <div className='disclaimer'>
                            <img alt='img' className='info-icon' src='/asset/info.png' width='20' />
                            <span> Call may be recorded for quality & training purposes. </span>
                        </div>
                    </div>
                </div>}
                <img alt="img" className="leftCircle leftCircleDiv" src="/asset/left.png" />
                </>}
            </span>
        </>
    )
}