import React, { useState, useEffect, useContext } from 'react';
import { useParams } from "react-router-dom";
import Welcome from "../welcome";
import Meeting from "../meeting";
import Feedback from '../feedback';
import { fetchAppDetails } from '../../../services';
import Meeting_Check from '../meeting/meeting';
import { MeetingContext } from '../../../context/MeetingContext';

export default function Home({ user }) {
    const { appId } = useParams();
    const context = useContext(MeetingContext)

    const [screen, setScreen] = useState("welcome");
    // const [user, setUser] = useState("bd");
    const [appDetails, setAppDetails] = useState({});
    const [meetingId, setMeetingId] = useState();
    const [apiStatus, setAPIStatus] = useState('pending')

    useEffect(() => {
        context.setAppId(appId)
        fetchAppDetails(appId).then(function (res) {
            setAppDetails(res.data)
            context.setInitialMeetingUser(res.data)
            setLoacalUser(res.data)
            setAPIStatus('success')
        })
        .catch(()=>{
            setAPIStatus('failed')
        })
    }, [appId])
    
    const setLoacalUser=(res)=>{
        let userDetails ={ user:user, attendeeId:'' }
        if( user === 'patient' ) userDetails['attendeeId'] = res.leadId
        else if( user === 'doctor') userDetails['attendeeId'] = res.docAssigned
        else if( user === 'bd' ) userDetails['attendeeId'] = res.BDAssigned
        
        context.setLocalUser(userDetails)
    }

    const handelScreen = (type) => {
        setScreen(type)
    }

    const randomColor = () => {
        let colorArr = ['#512DA8', '#a82d92', '#2d96a8', '#2da860', '#a82d2d', '#a82d81']
        let randNum = Math.floor((Math.random() * 5) + 0);
        return colorArr[randNum]
    }

    const screenSelector = (selectedScreen) => {
        let docColor = randomColor();
        let patientColor = randomColor();
        switch (selectedScreen) {
            case "welcome":
                return <Welcome user={user} handelScreen={handelScreen} appDetails={appDetails} apiStatus={apiStatus} />;
            case "meeting":
                return <Meeting_Check handelScreen={handelScreen} appId={appId} setMeetingId={setMeetingId}/>;
                // return <Meeting user={user} handelScreen={handelScreen} appDetails={appDetails} appId={appId} setMeetingId={setMeetingId} color={{ doc: docColor, patient: patientColor }} />;
            case "feedback":
                return <Feedback user={user} handelScreen={handelScreen} appDetails={appDetails} appId={appId} meetingId={meetingId} />;
            default:
                return null;
        }
    };

    return (
        <>
            {screenSelector(screen)}
        </>
    )
}