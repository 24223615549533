// import { useEffect, useState } from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Doctor from "../layout/screens/doctor";
import Patient from "../layout/screens/patient";
import BD from "../layout/screens/bd";
import Home from "../layout/screens/home";
import Meeting_Check from "../layout/screens/meeting/meeting";
import MeetingScreenDesign from "../layout/screens/meeting/MeetingScreenDesign";
import PermissoionSettings from "../common/PermissionSettings";
import AppNotCompatible from "../_components/AppNotCompatible";

export default function Routing() {
  return (
    <BrowserRouter>
      <Routes>
        {/* <Route exact path="/meeting" element={<AppNotCompatible />} /> */}
        <Route exact path="/join/patient-details/:appId" element={<Home user='doctor' />} />
        <Route path="/join/appointment-details/:appId" element={<Home user='patient' />} />
        <Route path="/join/onlineconsult-details/:appId" element={<Home user='bd' />} />
      </Routes>
    </BrowserRouter>
  );
}