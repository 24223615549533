import React, { useEffect, useState, useRef } from 'react';
import './App.css';
import MeetingProvider from './context/MeetingContext';
import Layout from "./layout";

function App() {

  const [isOnline, setIsOnline] = useState(true)
  const [isSlowNet, setIsSlowNet] = useState(false)
  const [isLowBattery, setIsLowBattery] = useState(false)

  // useEffect(() => {
  //   if (navigator && navigator.connection && navigator.connection.effectiveType) {
  //     detectNetworkChange(navigator.connection.effectiveType)
  //   }

  //   window.addEventListener("offline", () => {
  //     console.log("No Internet")
  //     setIsOnline(false)
  //   });

  //   window.addEventListener("online", () => {
  //     console.log("Connected Internet")
  //     setIsOnline(true)
  //   });

  //   navigator && navigator.connection && navigator.connection.addEventListener('change', () => {
  //     if (navigator && navigator.connection && navigator.connection.effectiveType) {
  //       detectNetworkChange(navigator.connection.effectiveType)
  //     }
  //   });

  //   //   // navigator.getBattery().then((battery) => {
  //   //   //   checkBattery(battery)
  //   //   //   battery.addEventListener('levelchange', () => {
  //   //   //     checkBattery(battery)
  //   //   //   });
  //   //   // });

  // }, [])

  const checkBattery = (battery) => {
    if (battery.level < 0.05) {
      setIsLowBattery(true)
    } else {
      setIsLowBattery(false)
    }
  }

  const detectNetworkChange = (effectiveType) => {
    console.log("navigator.connection.type", effectiveType)
    let status = false;
    switch (effectiveType) {
      case "slow-2g":
        status = true
        break;
      case "2g":
        status = true
        break;
      case "3g":
        status = false
        break;
      case "4g":
        status = false
        break;
      default: break;
    }

    if (status) {
      setIsSlowNet(true)
    } else {
      setIsSlowNet(false)
    }
  }

  const tryAgainButton = () => {
    window.location.reload();
  }
  return (
    <>
      {isLowBattery &&
        <span className='lowBattery'>
          <div id="screen-low-battery">
            <div className="row lowBatteryImg">
              <img alt='img' src="/asset/Low Battery.svg" />
            </div>
            <div className="row lowBatteryHead">
              <div className="col-123">
                Low Battery
              </div>
            </div>
            <div className="row lowBatteryBody">
              <div className="col-123">
                Your device battery is too low. The call may get disconnected abruptly. Please connect it to a charger now.
              </div>
            </div>
          </div>
        </span>
      }
      {isSlowNet &&
        <div id="screen-slow-internet">
          <span>Slow internet! Please check your connection.</span>
        </div>
      }
      {!isOnline &&
        <span id="screen-no-internet">
          <div className="container noInternetNew">
            <div className="img-part">
              {/* <img alt='img' src='/asset/No internet.svg' /> */}
              <svg xmlns="http://www.w3.org/2000/svg" width="200" height="200" viewBox="0 0 200 200">
                <defs>
                  <clipPath id="clip-path">
                    <rect id="Rectangle_1" data-name="Rectangle 1" width="200" height="200" transform="translate(80 88)" fill="none" />
                  </clipPath>
                  <clipPath id="clip-path-2">
                    <rect id="Rectangle_2" data-name="Rectangle 2" width="128.804" height="131.25" transform="translate(35.597 20.537)" fill="none" />
                  </clipPath>
                </defs>
                <g id="No_internet" data-name="No internet" transform="translate(-80 -88)" clip-path="url(#clip-path)">
                  <g id="Group_9" data-name="Group 9" transform="translate(0 21)">
                    <g id="Layer_1" data-name="Layer 1" transform="translate(80 108.494)">
                      <g id="Group_5" data-name="Group 5">
                        <path id="Path_43977" data-name="Path 43977" d="M191.816,151.786c-.936,1.879-1.88,3.629-2.8,5.226H19.537a60.489,60.489,0,0,1-3.946-5.226C-5.351,120.836-7.817,61.128,24.506,27.137c9.088-9.557,24.424-18.43,38.44-22.711C113.477-11,114.1,22.992,137.681,49.522s48.588,14.61,60.539,57.421c4.655,16.675-.642,33.242-6.4,44.842Z" fill="#f3eef9" />
                        <g id="Group_2" data-name="Group 2" clip-path="url(#clip-path-2)">
                          <g id="Group_1" data-name="Group 1">
                            <path id="Path_43978" data-name="Path 43978" d="M163.237,80.764V37.575a17.043,17.043,0,0,0-17.042-17.038H53.867A17.045,17.045,0,0,0,36.82,37.575V52A1.737,1.737,0,0,0,35.6,53.664v6.667a1.741,1.741,0,0,0,1.223,1.661V73.567A1.741,1.741,0,0,0,35.6,75.228v14.7A1.748,1.748,0,0,0,36.82,91.59V95.13A1.739,1.739,0,0,0,35.6,96.792v14.695a1.745,1.745,0,0,0,1.223,1.666V235.8a17.038,17.038,0,0,0,17.047,17.04H146.2a17.041,17.041,0,0,0,17.019-16.164c.015-.291.022-.585.022-.876V110.216a1.74,1.74,0,0,0,1.166-1.64V82.4a1.745,1.745,0,0,0-1.166-1.64Zm-6.02,155.04c0,.3-.011.586-.037.876A11.018,11.018,0,0,1,146.2,246.824H53.867A11.019,11.019,0,0,1,42.839,235.8V37.575A11.019,11.019,0,0,1,53.867,26.556H71.053v2.465a5.417,5.417,0,0,0,5.415,5.414h48.238a5.416,5.416,0,0,0,5.419-5.414V26.556h16.069a11.014,11.014,0,0,1,11.021,11.019V235.8Z" fill="#00263e" />
                            <path id="Path_43979" data-name="Path 43979" d="M157.217,37.575V235.8c0,.3-.011.588-.037.876a11.018,11.018,0,0,1-10.987,10.142H53.865A11.018,11.018,0,0,1,42.839,235.8V37.575A11.018,11.018,0,0,1,53.865,26.556h92.328a11.016,11.016,0,0,1,11.022,11.019Z" fill="#fff" />
                            <path id="Path_43980" data-name="Path 43980" d="M109.157,35.191h-18.2a1.78,1.78,0,0,1-1.781-1.781h0a1.78,1.78,0,0,1,1.781-1.781h18.2a1.78,1.78,0,0,1,1.781,1.781h0A1.78,1.78,0,0,1,109.157,35.191Z" fill="#5b5d6b" />
                          </g>
                        </g>
                        <g id="Group_4" data-name="Group 4">
                          <circle id="Ellipse_1" data-name="Ellipse 1" cx="6.985" cy="6.985" r="6.985" transform="translate(93.016 111.484)" fill="#3d7cc9" />
                          <path id="Path_43981" data-name="Path 43981" d="M144.821,83.723l-.985.974a3.922,3.922,0,0,1-5.538-.03,54.073,54.073,0,0,0-76.6,0,3.922,3.922,0,0,1-5.538.03l-.983-.973a3.91,3.91,0,0,1-.033-5.536l.054-.054a63.307,63.307,0,0,1,89.6,0l.054.054a3.91,3.91,0,0,1-.033,5.536Z" fill="#cfcfcf" />
                          <path id="Path_43982" data-name="Path 43982" d="M133.5,94.922l-1.008,1a3.919,3.919,0,0,1-5.527-.02,38.134,38.134,0,0,0-53.934,0,3.919,3.919,0,0,1-5.527.02l-1.009-1a3.867,3.867,0,0,1-.037-5.475l.027-.027a47.4,47.4,0,0,1,67.024,0l.027.027a3.87,3.87,0,0,1-.037,5.478Z" fill="#cfcfcf" />
                          <path id="Path_43983" data-name="Path 43983" d="M122.117,106.187l-.963.954a3.913,3.913,0,0,1-5.508,0,22.2,22.2,0,0,0-31.292,0,3.913,3.913,0,0,1-5.508,0l-.963-.952a3.911,3.911,0,0,1-.019-5.55,31.38,31.38,0,0,1,44.273,0,3.911,3.911,0,0,1-.019,5.55Z" fill="#cfcfcf" />
                          <g id="Group_3" data-name="Group 3">
                            <path id="Path_43984" data-name="Path 43984" d="M100,104.972h0a5.691,5.691,0,0,1-5.684-5.43l-1.7-37.164A7.4,7.4,0,0,1,100,54.645h0a7.4,7.4,0,0,1,7.387,7.733l-1.7,37.164A5.691,5.691,0,0,1,100,104.972Z" fill="#3d7cc9" />
                            <path id="Path_43985" data-name="Path 43985" d="M100,106.25A6.956,6.956,0,0,1,93.042,99.6l-1.7-37.164a8.67,8.67,0,1,1,17.323,0l-1.7,37.166A6.956,6.956,0,0,1,100,106.251Zm0-50.328a6.119,6.119,0,0,0-6.113,6.4l1.7,37.166a4.415,4.415,0,0,0,8.82,0l1.7-37.166a6.119,6.119,0,0,0-6.113-6.4Z" fill="#fff" />
                          </g>
                        </g>
                        <path id="Path_43986" data-name="Path 43986" d="M197.962,154.4a2.61,2.61,0,0,1-2.614,2.612H4.652a2.613,2.613,0,0,1,0-5.226h190.7a2.614,2.614,0,0,1,2.614,2.614Z" fill="#00263e" />
                      </g>
                      <path id="Path_43987" data-name="Path 43987" d="M159.615,9.928a3.987,3.987,0,1,1,3.987,3.987A3.992,3.992,0,0,1,159.615,9.928Zm1.752,0A2.234,2.234,0,1,0,163.6,7.695,2.236,2.236,0,0,0,161.367,9.928Z" fill="#3d7cc9" />
                      <g id="Group_6" data-name="Group 6">
                        <path id="Path_43988" data-name="Path 43988" d="M16.89,117.07a.985.985,0,0,0,1.392,0l6.956-6.956a.984.984,0,1,0-1.392-1.392l-6.956,6.956A.985.985,0,0,0,16.89,117.07Z" fill="#3d7cc9" />
                        <path id="Path_43989" data-name="Path 43989" d="M16.89,110.113l6.956,6.956a.984.984,0,1,0,1.392-1.392l-6.956-6.956a.984.984,0,1,0-1.392,1.392Z" fill="#3d7cc9" />
                      </g>
                      <circle id="Ellipse_2" data-name="Ellipse 2" cx="1.042" cy="1.042" r="1.042" transform="translate(145.985 0)" fill="#00263e" />
                      <circle id="Ellipse_3" data-name="Ellipse 3" cx="1.042" cy="1.042" r="1.042" transform="translate(14.119 64.301)" fill="#00263e" />
                      <circle id="Ellipse_4" data-name="Ellipse 4" cx="1.691" cy="1.691" r="1.691" transform="translate(175.104 110.222)" fill="#3d7cc9" />
                    </g>
                    <g id="Group_8" data-name="Group 8">
                      <g id="Group_7" data-name="Group 7" transform="translate(196.068 88.13)">
                        <path id="Path_43936" data-name="Path 43936" d="M958.754,85.136c.177-1.709,3.675-2.669,7.812-2.143s7.347,2.338,7.17,4.048-3.675,2.669-7.812,2.143-7.347-2.338-7.17-4.048" transform="translate(-958.747 -72.302)" fill="#00263e" />
                        <path id="Path_43935" data-name="Path 43935" d="M994.651,12.851c-1.848.31-4.066-2.309-4.954-5.851S989.586.335,991.434.025s4.066,2.309,4.954,5.851.111,6.665-1.737,6.975" transform="translate(-985.436 0)" fill="#666a70" opacity="0.5" />
                        <path id="Path_43937" data-name="Path 43937" d="M1020.632,21.474c-1.778-.674-2.1-3.908-.726-7.222s3.934-5.454,5.712-4.78,2.1,3.908.726,7.222-3.934,5.454-5.712,4.78" transform="translate(-1011.376 -8.158)" fill="#666a70" opacity="0.5" />
                        <path id="Path_43938" data-name="Path 43938" d="M1064.309,98.091a1.314,1.314,0,0,1-1.733.067,1.094,1.094,0,0,1-.427-1.552,1.314,1.314,0,0,1,1.733-.067,1.094,1.094,0,0,1,.427,1.552" transform="translate(-1048.827 -83.998)" fill="#fff" stroke="#666a70" stroke-width="0.5" />
                        <path id="Path_43939" data-name="Path 43939" d="M995.176,117.191a.13.13,0,0,1,.094-.239c1.927.181,5.732-2.591,6.028-2.852a.158.158,0,0,1,.208.007.126.126,0,0,1,0,.189c-.036.032-4.038,3.13-6.255,2.922a.161.161,0,0,1-.077-.028" transform="translate(-990.484 -99.544)" fill="#00263e" />
                        <path id="Path_43940" data-name="Path 43940" d="M961.635,112.024a.134.134,0,0,1-.059-.139.141.141,0,0,1,.174-.1c1.891.433,6.124-1.785,6.461-2a.159.159,0,0,1,.206.035.124.124,0,0,1-.028.186c-.041.026-4.523,2.54-6.7,2.042a.163.163,0,0,1-.055-.024" transform="translate(-961.213 -95.79)" fill="#00263e" />
                        <path id="Path_43941" data-name="Path 43941" d="M1010.474,121.663a.13.13,0,0,1,.094-.239c1.927.181,5.732-2.591,6.028-2.852a.157.157,0,0,1,.208.007.126.126,0,0,1,0,.189c-.036.032-4.038,3.13-6.255,2.922a.161.161,0,0,1-.077-.028" transform="translate(-1003.835 -103.446)" fill="#00263e" />
                        <path id="Path_43942" data-name="Path 43942" d="M976.156,118.45a.134.134,0,0,1-.059-.139.141.141,0,0,1,.174-.1c1.891.433,6.125-1.785,6.461-2a.159.159,0,0,1,.206.034.124.124,0,0,1-.029.186c-.041.026-4.523,2.54-6.7,2.042a.162.162,0,0,1-.055-.024" transform="translate(-973.885 -101.397)" fill="#00263e" />
                      </g>
                      <path id="Path_43990" data-name="Path 43990" d="M935.335,393.986c-45.13-33.439-30.728-60.715-17.119-67.239,5.419-2.6,10.536-1.66,10.647-.578s-3.887,4.256-10.2,4.9c-14,1.437-38.257-1.567-36.746-15.141,2.148-19.308,92.066-37.507,92.066-37.507" transform="translate(-780 -172)" fill="none" stroke="#00263e" stroke-linecap="round" stroke-width="1.5" stroke-dasharray="3 3" />
                    </g>
                  </g>
                </g>
              </svg>

            </div>
            <div className="text-container">
              <h2>Internet Connection Error</h2>
            </div>
            <div className="second-text">
              <p>Your internet seems to be not working.
                <span>Check your internet connection</span> <span>OR</span>
              </p>
            </div>
            <div className="footer-button">
              <button onClick={() => tryAgainButton()} id="try-again-button" className="try-again-button">Try Again</button>
            </div>
          </div>
        </span>
      }
      <MeetingProvider>
        <Layout />
      </MeetingProvider>
    </>
  );
}

export default App;
