import { Box } from '@mui/material';
import React, { useContext, useEffect } from 'react';
import logo from '../asset/Logo.png';
import { MeetingContext } from '../context/MeetingContext';
import useTimer from '../customHooks/timer';
import './style.css'
export default function Header({ sxProps, meetingSession, handelScreen }){
    const { timer, startTime, resetTimer, setStartTimer } = useTimer()
    const context = useContext(MeetingContext)
    const {
        localUser,
        doctor,
        patient,
        bd
    } = context

    useEffect(() =>{
        if(doctor.joined && patient.joined && bd.joined){
            resetTimer()
            setStartTimer(true)
        }
    },[doctor.joined,patient.joined,bd.joined])

    useEffect(()=>{
        if(timer === "00:00"){
            if(localUser === 'bd'){
                removeBDOnTimer()
            }
            else{
                setStartTimer(false)
            }
        }
    },[timer])
    
    const removeBDOnTimer = async() =>{
        await meetingSession.audioVideo.stopAudioInput();
        await meetingSession.audioVideo.stop()
        handelScreen('feedback')
    }

    return(
        <Box className="consultant_header" sx={{...sxProps}}>
            <center>
                <img alt='img' className="logo" src={logo} />
                {startTime && !bd.exit && localUser !== 'patient' &&
                    <div className='bdTimer'>
                        {timer}
                    </div>
                }
            </center>
        </Box>
    )
}