const MeetingReducer = (state,action) =>{
    const {type,payload} = action
    switch(type){
        case "SET_MEETING_USER_DETAILS":
            const {docAssigned,leadId,BDAssigned}=payload
            return{
                ...state,
                meetingAttendee:{
                [docAssigned]:{
                    ...state.meetingAttendee[docAssigned],
                    id: docAssigned,
                    title: 'Doctor',
                    name: payload?.doctorDetails.doctorName,
                    categorySpecialization: payload?.doctorDetails.categorySpecialization,
                },
                [leadId]:{
                    ...state.meetingAttendee[leadId],
                    id: leadId,
                    title: 'Patient',
                    name: payload?.patientDetails.patientName,
                    disease: payload?.patientDetails.disease,
                    city: payload?.patientDetails.city,
                },
                [BDAssigned]:{
                    ...state.meetingAttendee[BDAssigned],
                    id: BDAssigned,
                    title: 'BD',
                    name: payload?.BDName,
                }
            }
        }
        case "SET_MEETING_ID":
            return{
                ...state,
                meetingId: payload
            }
        case "SET_APP_ID":
            return {
                ...state,
                appId: payload
            }
        case "HANDLE_SCREEN":
            return{
                ...state,
                screen: payload,
            }
        case "SET_LOCAL_USER":
            return{
                ...state,
                localUser: payload.user,
                attendeeId: payload.attendeeId
            }
        case "DOCTOR_JOINED":
            return{
                ...state,
                doctor:{
                    joined: true,
                    exit: false,
                    id: payload
                } ,
            }
        case "DOCTOR_LEFT":
            return {
                ...state,
                doctor: {
                    joined: false,
                    exit: true,
                    id: payload
                }
            }
        case "PATIENT_JOINED":
            return{
                ...state,
                patient:{
                    joined: true,
                    exit: false,
                    id: payload
                }
            }
        case "PATIENT_LEFT":
            return {
                ...state,
                patient: {
                    joined: false,
                    exit: true,
                    id: payload
                }
            }
        case "BD_JOINED":
            return {
                ...state,
                bd: {
                    joined: true,
                    exit: false,
                    id: payload
                }
            }
        case "BD_LEFT":
            return {
                ...state,
                bd: {
                    joined: false,
                    exit: true,
                    id: payload
                }
            }
        case 'HANDLE_LOCAL_VIDEO':
            return {
                ...state,
                localVideo: payload
            }
    }
}
export default MeetingReducer;