import React from 'react';
import NotSupported from '../asset/not-supported.png';

const AppNotCompatible =({isCompatible, compatibilityInfo})=>{

    return(
        <div className='not_compatible_info'>
            <img className='not_compatible_img' src={NotSupported} />
            { compatibilityInfo?.browserInSupportList || isCompatible?
                <>
                    {!compatibilityInfo.browserSupported &&
                       <p className='not_compatible_txt'> Current browser version is not supported, kindly update your browser and rejoin the meeting. </p>
                    }
                    {compatibilityInfo.osName && !compatibilityInfo.osSupport &&
                        compatibilityInfo.osName === 'android' ?
                        <p className='not_compatible_txt'>
                            Current OS is not supported by our appication, Kindly use Android version above 5.0 for smooth experience.
                        </p>
                        :
                        compatibilityInfo.osName === 'ios' &&
                        <p className='not_compatible_txt'>  
                            Current OS is not supported by our application, kindly use iOS version above 10 for smooth experience.
                        </p>
                    }
                </>:
                <p className='not_compatible_txt'> 
                    This browser doesn't support Video Calling.<br/>
                        Suggested list of browsers:
                        <ul className='browser_list'>
                            <li> Google Chrome (latest three versions) </li>
                            <li> Mozilla Firefox (latest three versions) </li>
                            <li> Chromium Edge (latest three versions) </li>
                            <li> Safari (Latest version only) </li>
                        </ul> 
                    For best experience use Google Chrome or Safari. 
                </p>
            }
        </div>
    )
}

export default AppNotCompatible