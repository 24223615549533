export const detectBrowser = () => {

    let userAgent = navigator.userAgent;
    let browserName = "Not Supported";
    
    if(userAgent.match(/MiuiBrowser/i) || userAgent.match(/opr\//i)){
        browserName = 'Not Supported'
    }
    else if(userAgent.match(/chrome|chromium|crios/i) && !!window.chrome){
        browserName = "Chrome";
    }
    else if(userAgent.match(/firefox|fxios/i)){
        browserName = "Firefox";
    } 
    else if(userAgent.match(/safari/i)){
        browserName = "Safari";
    }
    else if(userAgent.match(/edge/i)){
        browserName = 'Microsoft Edge'
    }
    else if(userAgent.match(/edg/i)){
        browserName = "Edge";
    }
    
    return browserName
}