import React from "react";

const MeetingStatusNotifier = ({message}) =>{
    return(
        <div className="notify_meeting_message">
            <p> {message} </p>
        </div>
    )
}

export default MeetingStatusNotifier