var axios = require('axios');

let url = 'https://sjv216aji5.execute-api.ap-south-1.amazonaws.com/production/'
// let url = 'https://hc7k7bktla.execute-api.ap-south-1.amazonaws.com/staging/'
console.log(url,"this is my url ####")

export function fetchAppDetails(appId) {
    var data = { "appId": appId };

    var config = {
        method: 'post',
        url: url+'getappointmentdetails',
        headers: { 'Content-Type': 'application/json;charset=UTF-8' },
        data: data
    };

    return axios(config)
}

export function joinMeeting(appId, attendeeId) {
    var data = { "appointment_id": appId, "external_attendee_id": attendeeId };

    var config = {
        method: 'post',
        url: url+'join',
        headers: { 'Content-Type': 'application/json;charset=UTF-8' },
        data: data
    };

    return axios(config)
}

export function submitFeedback(data) {
    var config = {
        method: 'post',
        url: url+'updateappointmentfeedback',
        headers: { 'Content-Type': 'application/json;charset=UTF-8' },
        data: data
    };

    return axios(config)
}

export function removeAttendee(meetingId) {
    var data = { "meetingId": meetingId };

    var config = {
        method: 'delete',
        url: url+'remove-attendee',
        headers: { 'Content-Type': 'application/json;charset=UTF-8' },
        data: data
    };

    return axios(config)
}

export function deviceInfo(data){
    var config = {
        method: 'post',
        url: url + 'device-info',
        headers: { 'Content-Type': 'application/json;charset=UTF-8' },
        data: data
    }

    return axios(config)
}