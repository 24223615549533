
import React, { useEffect, useRef } from "react";

const StreamAudio=({ meetingSession, isAudioVideo }) => {
    const audioRef = useRef(null);
  
    useEffect(() => {
      if (!meetingSession || !audioRef.current || !isAudioVideo) {
        console.error("No audio element found.");
        return;
      }

      const audioElement = audioRef.current;

      meetingSession.audioVideo.bindAudioElement(audioElement);
    }, [meetingSession,isAudioVideo]);
  
    return(
        <audio ref={audioRef} style={{ display: "hidden" }}/>
    )
  }

export default StreamAudio