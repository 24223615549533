import React, { useEffect, useState, useRef, useContext } from 'react';
import Grid from '@mui/material/Grid';
import { submitFeedback } from '../../../services';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import { MeetingContext } from '../../../context/MeetingContext';

const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

export default function Feedback({ user, handelScreen, appId, meetingId, appDetails }) {

    const [docRate, setDocRate] = useState(0);
    const [videoRate, setVideoRate] = useState(0);
    const [isConnected, setIsConnected] = useState(null);
    const [patientInputTxt, setPatientInputTxt] = useState();
    const videoTime = new Date() - new Date(localStorage.getItem("joinTime"));
    const [open, setOpen] = React.useState(false);
    const [alertTxt, setAlertTxt] = React.useState("");
    const [isConnectedBD, setIsConnectedBD] = useState(null);
    const [txtDoc, setTxtDoc] = useState(null);
    const context = useContext(MeetingContext)

    const handleClick = () => {
        setOpen(true);
    };

    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        setOpen(false);
    };

    const convertTime = (millis) => {
        var minutes = Math.floor(millis / 60000);
        var seconds = ((millis % 60000) / 1000).toFixed(0);
        return minutes + "m : " + (seconds < 10 ? '0' : '') + seconds + "s ";
    }

    const submitFeedbackDoc = () => {
        if(!isConnected){
            return
        }
        if(isConnected=='no'){
            if(!txtDoc){
                return
            }
        }
        // if (isConnected == null) {
        //     setAlertTxt("Were you able to complete the consultation satisfactorily?")
        //     handleClick()
        //     return
        // }
        let data = {
            "appointment_id": appId,
            "is_connected": true,
            "doctor_feedback": {
                "is_connected": isConnected == "yes" ? true : false,
                "prescription": {
                }
            },
            "meeting_id": meetingId
        }
        submitFeedback(data).then(res => {
            const deeplink = `https://pristyndocapp.page.link/XktS?apptId=${appId}`;
            window.location.href = deeplink;
        })
    }
    const submitFeedbackPatient = () => {
        if(!videoRate || !docRate){
            return
        }
        // if (docRate == 0) {
        //     setAlertTxt("Doctor rating is required!")
        //     handleClick()
        //     return
        // }
        // if (videoRate == 0) {
        //     setAlertTxt("Video rating is required!")
        //     handleClick()
        //     return
        // }
        let data = {
            "meeting_id": meetingId,
            "appointment_id": appId,
            "patient_feedback": {
                "call_rating": videoRate,
                "doctor_rating": docRate,
                "call-feedback": '',
                "doctor-feedback": ''
            }
        };
        submitFeedback(data).then(res => {
            window.location.href = 'https://bit.ly/PrstynApp';
        })
    }

    const submitFeedbackBD = () => {
        if(!isConnectedBD){
            return
        }
        let data = {
            "appointment_id": appId,
            "is_connected": true,
            "bd_feedback": {
                "is_connected": isConnectedBD == "yes" ? true : false,
                "prescription": {
                }
            },
            "meeting_id": meetingId
        }
        submitFeedback(data).then(res => {
            const deeplink = `https://jupiter.pristyntech.com/`;
            window.location.href = deeplink;
        })
    }

    const reJoin = () =>{
        context.setLocalVideoTile(true)
        handelScreen("meeting")
        localStorage.setItem("joinTime", new Date());
    }

    return (
        <>
            <span id="screen3">
                <div className="row logoFixed">
                    <div className="col headder">
                        <center>
                            <img alt='img' className="logo" src="/asset/Logo.png" />
                        </center>
                    </div>
                </div>
                {user === 'patient' &&
                    <div>
                        <div className="row">
                            {/* <div className="col" style={{ "paddingLeft": "30px", "marginTop": "30px" }}>
                                Your Online Consultation has ended
                            </div> */}
                            <div className="col reJoinButton" style={{ "marginTop": "20px" }}>
                                <button onClick={() => reJoin()} id="rejoin" className="submitFeedbackButt">Rejoin</button>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-12 rateHead">
                                How would you rate {appDetails?.doctorDetails?.doctorName}?
                            </div>
                            {/* <div className="col-12 rateSub">
                                Click one option to share your rating:
                            </div> */}
                            <div className="col-12 emoje">
                                <img onClick={() => setDocRate(1)} style={docRate >= 1 ? { "filter": "none" } : {}} alt='img' src="/asset/emoje/Very unhappy.png" />
                                <img onClick={() => setDocRate(2)} style={docRate >= 2 ? { "filter": "none" } : {}} alt='img' src="/asset/emoje/Unhappy.png" />
                                <img onClick={() => setDocRate(3)} style={docRate >= 3 ? { "filter": "none" } : {}} alt='img' src="/asset/emoje/Neutral.png" />
                                <img onClick={() => setDocRate(4)} style={docRate >= 4 ? { "filter": "none" } : {}} alt='img' src="/asset/emoje/Happy.png" />
                                <img onClick={() => setDocRate(5)} style={docRate === 5 ? { "filter": "none" } : {}} alt='img' src="/asset/emoje/Very Happy.png" />
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-12 rateHead">
                                Rate the video experience?
                            </div>
                            {/* <div className="col-12 rateSub">
                                Click one option to share your rating:
                            </div> */}
                            <div className="col-12 emoje">
                                <img onClick={() => setVideoRate(1)} style={videoRate >= 1 ? { "filter": "none" } : {}} alt='img' src="/asset/emoje/Very unhappy.png" />
                                <img onClick={() => setVideoRate(2)} style={videoRate >= 2 ? { "filter": "none" } : {}} alt='img' src="/asset/emoje/Unhappy.png" />
                                <img onClick={() => setVideoRate(3)} style={videoRate >= 3 ? { "filter": "none" } : {}} alt='img' src="/asset/emoje/Neutral.png" />
                                <img onClick={() => setVideoRate(4)} style={videoRate >= 4 ? { "filter": "none" } : {}} alt='img' src="/asset/emoje/Happy.png" />
                                <img onClick={() => setVideoRate(5)} style={videoRate === 5 ? { "filter": "none" } : {}} alt='img' src="/asset/emoje/Very Happy.png" />
                            </div>
                        </div>
                        {/* <div className="row">
                            <div className="col-12 rateHead">
                                Additional Comments
                            </div>
                            <div className="col-12 rateSub">
                                <textarea onInput={event => setPatientInputTxt(event.target.value)} id="feedbackCom" className="txtArea"></textarea>
                            </div>
                        </div> */}
                        <div className="row">
                            <div className="col reJoinButton" style={{ "marginTop": "10px", "marginBottom": "20px" }}>
                                <button disabled={!videoRate || !docRate} onClick={event => submitFeedbackPatient()} id="submitFeedbackButt" className="submitFeedbackButt">Submit Feedback</button>
                            </div>
                        </div>
                    </div>
                    // </span>
                }
                {user === 'doctor' &&
                    <div>
                        {/* <div className="row">
                            <div className="col" style={{ "paddingLeft": "30px", "marginTop": "30px" }}>
                                This Consultation was {convertTime(videoTime)} long
                            </div>
                        </div> */}
                        <div className="row">
                            <div className="col reJoinButton" style={{ "marginTop": "20px", "marginBottom": "20px" }}>
                                <button onClick={() => { reJoin() }} id="submitFeedbackButt" className="submitFeedbackButt">Rejoin</button>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-12" style={{ "paddingLeft": "30px", "marginTop": "30px" }}>
                                Dear {appDetails?.doctorDetails?.doctorName},<br />
                                Were you able to complete video consultation satisfactorily ?
                            </div>
                        </div>
                        <div className="row">
                            <Grid container spacing={2} style={{ 'textAlign': 'center', 'marginTop': '10px' }}>
                                <Grid item xs={6}>
                                    <input onChange={event => setIsConnected(event.target.value)} type="radio" id="html" name="fav_language" value="yes" />
                                    Yes
                                </Grid>
                                <Grid item xs={6}>
                                    <input onChange={event => setIsConnected(event.target.value)} type="radio" id="html" name="fav_language" value="no" />
                                    No
                                </Grid>
                            </Grid>
                        </div>
                        {isConnected == 'no' &&
                            <div className="row">
                                <div className="col-12 rateHead">
                                    What went wrong?
                                </div>
                                <div className="col-12 rateSub">
                                    <textarea id="feedbackCom" onChange={event => setTxtDoc(event.target.value)} className="txtArea"></textarea>
                                </div>
                            </div>
                        }
                        <div className="row">
                            <div className="col submitFeedback1">
                                <button disabled={!isConnected || (isConnected!='yes' && !txtDoc)} onClick={event => submitFeedbackDoc()} id="rejoin" className="submitFeedbackButt">Submit Feedback</button>
                            </div>
                        </div>
                    </div>
                }
                {user === 'bd' &&
                    <>
                        <div className="row">
                            <div className="col-12" style={{ "paddingLeft": "30px", "marginTop": "30px" }}>
                                Were you able to ensure that patient & doctor met?
                            </div>
                        </div>
                        <div className="row">
                            <Grid container spacing={2} style={{ 'textAlign': 'center', 'marginTop': '10px' }}>
                                <Grid item xs={6}>
                                    <input onChange={event => setIsConnectedBD(event.target.value)} type="radio" id="html" name="fav_language" value="yes" />
                                    Yes
                                </Grid>
                                <Grid item xs={6}>
                                    <input onChange={event => setIsConnectedBD(event.target.value)} type="radio" id="html" name="fav_language" value="no" />
                                    No
                                </Grid>
                            </Grid>
                        </div>
                        <div className="row">
                            <div className="col submitFeedback1">
                                <button disabled={!isConnectedBD} onClick={event => {submitFeedbackBD()}} id="rejoin" className="submitFeedbackButt">Submit Feedback</button>
                            </div>
                        </div>
                    </>
                }
            </span>
            <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
                <Alert onClose={handleClose} severity="warning" sx={{ width: '100%' }}>
                    {alertTxt}
                </Alert>
            </Snackbar>
        </>
    )
}