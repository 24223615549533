import React from "react";

export default function ControllerIcon({imageUrl,text,type,imgStyle,textStyle}){
    const imageStyle = type === "default" ? "icon" : imgStyle
    const txtStyle = type === "default" ? "icon-text" : textStyle
    return(
        <>
            <img className={imageStyle} src={imageUrl} alt="image" />
            <p className={txtStyle}> {text} </p>
        </>
    )
}