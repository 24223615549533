import React from "react";
import loader from '../asset/Loader placement.svg'

const Loader = () =>{
    return(
        <div className="loader-contianer">
            <img alt="loader" className="loader-img" src={loader} />
            <p> Please wait while we are checking system and browser compatibility </p>
        </div>
    )
}

export default Loader