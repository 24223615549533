import React, { useContext, useEffect, useState } from 'react'
import { MeetingContext } from '../context/MeetingContext'
import { deviceInfo } from '../services'
const browserSupportedVer ={
    chrome:{
        desktop: 78,
        mobile: 10
    },
    safari:{
        desktop: 12,
        mobile: 12.2
    },
    edge:{
        desktop: 79,
        mobile: 78
    },
    firefox:{
        desktop: 75,
        mobile: 10
    }
}

const OSSupportedVer = {
    ios: 10,
    android: 5.0
}

const browserArray = ['chrome','mobile safari','edge','firefox', 'safari']
const osNameArray = ['linux', 'ubuntu', 'macos', 'windows', 'window']

const useBrowserCompatible = (appId) =>{
    const [attributes,setAttributes] = useState(null)
    const [compatibilityInfo, setCompatibilityInfo] = useState(null)
    const [isCompatible, setCompatibility] = useState(true)
    const [reason,setReason] = useState('')
    const context = useContext(MeetingContext)
    const {attendeeId,meetingId, meetingAttendee} = context

    useEffect(()=>{
        if(!attributes){
            return
        }
        checkCompatiblity()
    },[attributes])

    // useEffect(()=>{
    //     sendDeviceInfo()
    // },[compatibilityInfo])

    const checkCompatiblity =()=>{
        const {browserName, browserVersion, osName, osVersion} = attributes

        if(browserName && browserVersion && osName){
            const currentBrowser = browserName.toLowerCase()
            const currentOSVer =  parseInt(osVersion)
            const currentBrowserVer = parseInt(browserVersion)
            const currentOS = osName.toLowerCase()

            // check for browser supported by chime
            if(!browserArray.includes(currentBrowser)){
                setCompatibilityInfo({
                    browserInSupportList: false,
                    browserSupported: false
                })
                setReason('Browser Not Supported')
                setCompatibility(false)
                return
            }

            // desktop support check
            if( osNameArray.includes(currentOS) ){
                if( currentBrowserVer > browserSupportedVer[currentBrowser].desktop){
                    setCompatibilityInfo({
                        browserInSupportList:true,
                        currentBrowser: currentBrowser,
                        browserSupported: true,
                        osName: osName,
                        osSupport: true
                    })
                }
                else{
                    setCompatibilityInfo({
                        browserInSupportList: true,
                        currentBrowser: currentBrowser,
                        browserSupported: false,
                        osName: osName,
                        osSupport: true
                    })
                    setCompatibility(false)
                    setReason('Desktop browser is not supported')
                    return
                }
            }

            // mobile support check
            else if(currentOS === 'android'){
                if(currentBrowserVer > browserSupportedVer[currentBrowser].mobile && currentOSVer > OSSupportedVer[currentOS]){
                    setCompatibilityInfo({
                        browserInSupportList: true,
                        osName: osName,
                        osSupport: true,
                        currentBrowser: currentBrowser,
                        browserSupported: true
                    })
                }
                else{
                    setCompatibilityInfo({
                        browserInSupportList: true,
                        osName: osName,
                        osSupport: false,
                        currentBrowser: currentBrowser,
                        browserSupported: false
                    })
                    setCompatibility(false)
                    setReason('Android device browser is not supported')
                    return
                }
            }

            else{
                setCompatibilityInfo({
                    browserInSupportList: true,
                    osName: osName,
                    osSupport: true,
                    currentBrowser: currentBrowser,
                    browserSupported: true
                })
            }
        }
    }

    // const sendDeviceInfo = () =>{
    //     if(!attributes){
    //         return
    //     }
    //     const {browserName, browserVersion, osName, osVersion} = attributes

    //     const data = {
    //         meetingId:meetingId,
    //         appointmentId:appId,
    //         reason: isCompatible? 'No reason':reason,
    //         userId:attendeeId,
    //         userTitle: meetingAttendee[attendeeId].title,
    //         userName: meetingAttendee[attendeeId].name,
    //         deviceDetails: {browserName, browserVersion, osName, osVersion}
    //     }
    //     deviceInfo(data)
    // }
    return { attributes, compatibilityInfo, isCompatible, setAttributes }
}

export default useBrowserCompatible