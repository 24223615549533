import React, { createContext,useReducer } from "react";
import { InitialState } from "./InitialState";
import MeetingReducer from "./MeetingReducer";

export const MeetingContext = createContext(InitialState)

export default function MeetingProvider({ children }){
    const [state, disptach] = useReducer(MeetingReducer,InitialState)

    const setInitialMeetingUser = (response) => {
        disptach({
            type:'SET_MEETING_USER_DETAILS',
            payload: response,
        })
    }

    const setLocalUser = (user)=>{
        disptach({
            type:'SET_LOCAL_USER',
            payload: user,
        })
    }

    const setMeetingId = (meetId) =>{
        disptach({
            type: 'SET_MEETING_ID',
            payload: meetId
        })
    }

    const setAppId = (id) =>{
        disptach({
            type: 'SET_APP_ID',
            payload: id
        })
    }

    const doctorJoin = (id) => {
        disptach({
            type: 'DOCTOR_JOINED',
            payload: id
        })
    }

    const patientJoin = (id) => {
        disptach({
            type: 'PATIENT_JOINED',
            payload: id
        })
    }

    const bdJoin = (id) => {
        disptach({
            type: 'BD_JOINED',
            payload: id
        })
    }

    const doctorExit = (id) => {
        disptach({
            type: 'DOCTOR_LEFT',
            payload: id
        })
    }

    const patientExit = (id) => {
        disptach({
            type: 'PATIENT_LEFT',
            payload: id
        })
    }

    const bdExit = (id) => {
        disptach({
            type: 'BD_LEFT',
            payload: id
        })
    }

    const setLocalVideoTile = (status) =>{
        console.log('HANDLE_LOCAL_VIDEO',status)
        disptach({
            type: 'HANDLE_LOCAL_VIDEO',
            payload: status
        })
    }

    const value = {
        meetingAttendee: state?.meetingAttendee,
        localUser: state?.localUser,
        attendeeId: state?.attendeeId,
        appId: state?.appId,
        screen: state?.screen,
        doctor: state?.doctor,
        bd: state?.bd,
        patient: state?.patient,
        meetingId: state?.meetingId,
        localVideo: state?.localVideo,
        setInitialMeetingUser,
        setLocalUser,
        setAppId,
        doctorJoin,
        doctorExit,
        patientJoin,
        patientExit,
        bdJoin,
        bdExit,
        setMeetingId,
        setLocalVideoTile,
        disptach
    }

    return (
        <MeetingContext.Provider value={value}> {children} </MeetingContext.Provider>
    )
}